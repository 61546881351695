<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <keep-alive><FilterSection @change="onFilterChange" /></keep-alive>
    <section class="main-chart-container base-shadow">
      <FunnelChart :chartData="chartData" @onChartClick="handleChartClick" />
    </section>
  </section>
</template>

<script>
import { GetStatisticsStages } from "@/views/statisticAnalysis/api";
import FilterSection from "./components/FilterSection";
import FunnelChart from "./components/FunnelChart";
import { ShowApiError } from "@/request/error";
import PageHeader from "@/components/PageHeader";
export default {
  components: { FilterSection, FunnelChart, PageHeader },
  data() {
    return {
      chartData: [],
      params: {},
      loading: false,
    };
  },
  created() {
    this.getChartData();
  },

  methods: {
    getChartData() {
      GetStatisticsStages(this.params)
        .then((res) => {
          this.chartData = res.data;
        })
        .catch((err) => ShowApiError("获取漏斗图数据异常", err));
    },
    onFilterChange(data) {
      this.params = data;
      this.getChartData();
    },
    handleChartClick(params) {
      this.$router.push({
        name: "CientsList",
        params: {
          ...this.params,
          stageId: params.data.stageId === 1 ? null : [params.data.stageId],
          disabled: false
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-chart-container {
  box-sizing: border-box;
  margin: 10px 10px 10px 0;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  .chart {
    width: 100%;
    height: 600px;
  }
}
</style>